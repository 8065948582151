.oc-custom {
  /* width: 25vw !important; */
}

.oc-custom .offcanvas-header {
  background-color: #004b87;
  color: white;
}

.oc-custom.offcanvas-end {
  border-left: none !important;
}


.oc-custom .offcanvas-body {
  padding: 0px;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.oc-custom .offcanvas-body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.oc-custom .sub-header-tall {
  min-height: 180px;
  background-color: #004b87;
  /* background-image: linear-gradient(#004b87, #90acc7); */
}

.oc-custom .subtitle-short {
  /* min-height: 80px; */
  background-color: #004b87;
  /* background-image: linear-gradient(#004b87, #90acc7); */
  color: white;
  padding-top: 5px;
  padding-right: 15px;
  /* padding-bottom: 15px; */
  /* padding-left: 15px; */
  font-size: 14px;
}

.oc-custom .sub-header-short {
  /* min-height: 80px; */
  background-color: #004b87;
  /* background-image: linear-gradient(#004b87, #90acc7); */
  color: white;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 25px;
  padding-left: 15px;
}

.oc-custom.styled {
  border-top-left-radius: 80px 80px !important;
  border-bottom-left-radius: 80px 80px !important;
}

.offcanvas-header.styled {
  border-top-left-radius: 80px 80px !important;
  height: 100px;
  padding-left: 50px;
}


/* All button styles */

.oc-custom .offcanvas-header .btn-close {
  color: white !important;
}