.white-on-blue .navbar {
  background-color: #004b87 !important;
  padding: 10px 10px 10px 10px !important;
}

.white-on-blue .navbar-brand {
  /* this keeps the client name left aligned */
  flex-grow: 2;
  color: white !important;
  margin-top: -1px;
}

.navbar-brand-subtitle {
  margin-top: -4px;
  font-size: 10px;
}

.white-on-blue .nav-link {
  color: white !important;
  font-size: 16px;
  /* padding: 0px; */
}
.nav-link-dark {
  color: #333f48 !important;
  /* font-size: 16px; */
  /* padding: 0px; */
  text-decoration: none;
}

/* increase the spacing between top nav items */
.navbar-expand-sm .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-toggler {
  border: none;
}

.languageDropdown {
  color: white !important;
  font-size: 16px !important;
  text-decoration: none !important;
}

.nav-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 8px;
  /* min-height: 14px; */
  /* min-width: 14px; */
  line-height: .35;
  /* background-color: #FFB81C; */
  /* color: white; */
  background-color: white;
  border-radius: 8px;
  font-size: 10px;
  padding: 6px;
  z-index: 1;
}


/* ************************************************* */
/* mega menu styles */
.mega-menu .navbar {
  z-index: 300 !important;
}

.mega-menu .dropdown-menu.search-results {
  top: 65px;
  left: 0;
  /* min-height: 50vh; */
  width: 100vw !important;
  background-color: white;
  z-index: 1 !important;

}

.mega-menu.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  opacity: .2;
  z-index: 100 !important;
  height: 100vh;
  width: 100vw;
}


.mega-menu .search-item a {
  text-decoration: none !important;
  border-radius: 12px !important;
  color: #333f48 !important;
}

.mega-menu .search-item:hover {
  background-color: #eef2f5 !important;
  border-radius: 12px !important;
}
.mega-menu .search-item:focus {
  background-color: #eef2f5 !important;
  border-radius: 12px !important;
}
.mega-menu .search-item:focus-visible {
  outline: none !important;
}


.mega-menu .search-item-button {
  text-decoration: none;
  text-align: left;
  color: #333f48 !important;
}

.mega-menu .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #eef2f5 !important;
  padding: 20px;

}

.mega-menu .input-resize input[type="search"]  {
  position: relative;
  top: 0;
  left: 0;
  width: 70px !important;
  transition: all .5s;
  padding-left: 0px;
  /* border-radius: 5px !important; */
}
.mega-menu .input-resize input[type="search"]:focus  {
  width: 200px !important;
  top: 0;
  right: 100%;
} 

.mega-menu .input-fixed input[type="search"] {
  width: 100px !important;
  padding-left: 0px;
}

/* Added to fix Firefox issue with not displaying an X icon */
.input-container {
  position: relative;
}
.clear-button {
  position: absolute;
  right: 5px;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none; /* Hidden by default */
}
.clear-button.show {
  display: block
}
input[type="search"]::-webkit-search-cancel-button {
  display: none; /* Hide native "x" on Chrome/Edge */
}