.fcard .draggable {
  padding: 12px;
  margin: 5px 0;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 5px;
  cursor: grab;
  animation: slideIn 0.5s;
}


@keyframes slideIn {
  from {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }

  to {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.animate-deletion {
  transition: transform 0.5s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;
}


.fcard .draggable:hover {
  transform: scale(1.004);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.fcard .list-item:hover img {
  transform: scale(1.2);
}

.fcard .draggable.dragging {
  /* opacity: 0.2; */
  border: solid 3px #20CA31;
  background-color: #eef2f5;
}

/* hide the dropdown carot for the ... more icon */
.fcard .dropdown-toggle::after {
  display: none !important;
}

.fcard .checked-off button:after {
  display: inline-flex;
  content: '';
  background-image: url(/src/Images/checked-off-x.svg);
  background-size: 30px 30px;
  height: 30px;
  width: 30px;
  transition: none !important;
  transform: none !important;
}

.fcard .checked-on button:after {
  display: inline-flex;
  content: '';
  background-image: url(/src/Images/checked-on.svg);
  background-size: 30px 30px;
  height: 30px;
  width: 30px;
  transition: none !important;
  transform: none !important;
}

/* **************************************************** */




.fcard .list-item .item-container {
  display: flex;
  flex-direction: row;
}

.fcard .list-item .avatar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.fcard .list-item .more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;
}

.fcard .list-item .checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;
}

.fcard .list-item .title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  margin-left: 10px;
}

.fcard .list-item .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1;
  gap: 0 5px; /* gap sets the distance between the items in this container */
}

.fcard .list-item .sub-title {
  margin-top: 1px;
  font-size: 14px;
  margin-bottom: 1;
}

.fcard .list-item .description {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 1;
}