.widget-container {
    background-color: #fbfbfb;
}

/* hide the dropdown carot for the ... more icon */
.widget--menu .dropdown-toggle::after {
    display: none !important;
}
/* if show-toggle, then override the previous override */
.widget--menu .show-toggle .dropdown-toggle::after {
    display: inline-block !important;
}


.widget--card {
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
}

.widget-grid-wrapper {
    position: relative;
    /* padding: 8px 8px 8px 8px; */
}

.widget-grid-wrapper.fullscreen {
    z-index: 9999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.widget--menu {
    position: absolute;
    top: 0;
    right: 0;
}

/* .widget-grid-wrapper .widget--menu {
        display: none;
      }
      .widget-grid-wrapper:hover .widget--menu {
        display: inline;
      } */

.widget-grid-item {
    display: flex;
    background-color: white;
    padding: 12px;
    border-radius: 5px;
    /* border: solid 1px #f0f0f0; */
    height: 325px;
    /* box-shadow: 0px 5px 5px 0px rgba(46,61,73,0.15); */
}

.widget-grid-item h4 {
    color: gray !important;
    font-size: 1.0rem;
}

.widget-upper-right-x {
    position: absolute;
    top: 2px;
    right: 2px;
}


.dashboard-list-item {
    /* display: flex; */
    background-color: white;
    padding: 5px;
    padding-left: 10px;
    /* border-radius: 10px; */
    border: solid 1px #e0e0e0;
    /* box-shadow: 0px 2px 2px 0px rgba(46,61,73,0.15); */
    /* vertical-align: center; */
}

.dashboard-list-item:hover {
    cursor: move;
}

.dashboard-list-drag-enter {
    /* background-color: steelblue; */
    color: white;
}

.ndx-pill {

    background-color: steelblue;
    color: white;
    border: solid 1px white;
    border-radius: 25px;
    height: 30px;
    width: 30px;
    padding: 4px;
    line-height: 20px;
    margin-right: 10px;
    text-align: center;
}

/* .dashboard-list-gap {
      margin-top: 60px !important;
    } */
.grabbing * {
    cursor: grabbing;
}


.widget-library-card {
    height: 110px;
}

.widget-hero {
    background-color: steelblue;
    color: white;
    padding: 8px;
    height: 100%;
    border: solid 1px white;
    border-radius: 10px;
}

.widget-hero.up {
    background-color: steelblue;
}

.widget-hero.down {
    background-color: orange;
}

.widget-hero-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.widget-hero-footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
}