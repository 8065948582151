/* These are the styles for the CTA card container */

.cta-container {
  display: flex;
  flex-wrap: no-wrap;
  /* these margin overrides apologize for the internal padding */
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  /* overflow-y: scroll; */
  /* Hide scrollbar for IE, Edge */
  /* -ms-overflow-style: none; */
  /* Hide scrollbar for Firefox */
  /* scrollbar-width: none; */
  overflow-x: scroll;
  position: relative;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
/* .cta-container::-webkit-scrollbar {
  display: none;
} */

.cta-container::-webkit-scrollbar {
  height: 1px;
}
.cta-container:hover::-webkit-scrollbar {
  height: 12px;
}
.cta-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.cta-container::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #d5ac68;
  border-radius: 12px;
}

.cta-container::-webkit-scrollbar-thumb:hover {
  background-color: #f1db9d;
}

.cta-container::-webkit-scrollbar:vertical {
  display: none;
}

.cta-equal-columns > * {
  flex: 1;
}

.pseduo-track {
  background-color: #f1db9d;
  height: 2px;
  width: 100%;
  position: relative;
  top: -3px;
  z-index: -10;
}

@media (any-hover: none) {
  .pseduo-track {
    display: none;
  }
}

/* ******************************************************************* */
/* Styles for the CTA cards... */
/* ******************************************************************* */
.cta-card {
  background-color: white;
  border-radius: 5px;
  /* margin: 12px; */
}
/* this helps the cards appear as horizontally scrollable in mobile */
@media screen and (min-width: 375px) {
  .cta-card {
    min-width: 200px;
  }
}
.cta-card:hover {
  text-decoration: none;
  /* transform: scale(1.02); */
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}
.cta-card-link {
  color: #333f48 !important;
  text-decoration: none !important;
}

.cta-card .item-container {
  display: flex;
  flex-direction: row;
  padding: 12px 20px 12px 12px;
}

.cta-card .avatar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cta-card .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  flex-wrap: no-wrap;
}
.cta-card .count {
  font-size: 24px;
  font-weight: bold;
}

.cta-card .title {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.cta-card .title-no-count {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.cta-card.warning {
  /* background-color: #FFB81C; */
  /* color: white; */
}
.cta-card.critical {
  background-color: #E03C31;
  color: white;
}
.cta-card.warning.shadow {
  border: solid 1px #FFB81C;
  box-shadow: 0 2px 2px 0 silver;
}