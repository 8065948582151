.avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E03C31;
}

.avatar-container.match {
    background-color: #78BE20;
}
.avatar-container.close-match {
    background-color: #F5AA04;
}


.record-button {
    position: fixed;
    width: 80px;
    height: 80px;
    background-color: #004b87;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    bottom: 20px;
    right: 20px;
    /* animation: pulseScroller 1.2s ease-in-out 1; */
    animation: bounceScroller 1.2s ease-in-out 1; /* Bounce animation twice */
  }
  .record-button:hover {
    transform: scale(1.2);
  }

  .record-button-container {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .record-button-large {
    width: 80px;
    height: 80px;
    background-color: #004b87;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
  
    /* Position it at the bottom center of the page */
    position: relative;
    /* bottom: 80px;  */
    left: 50%;
    transform: translateX(-50%);
  }
  /* .record-button-large:hover {
    transform: scale(1.2);
  } */
  .record-button-large.recording {
    background-color: red;
  }
  .record-button-small {
    width: 50px;
    height: 50px;
    background-color: #004b87;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: bounceScroller 1.7s ease-in-out 1;
  }
  .record-button-small:hover {
    transform: scale(1.2);
  }

  @keyframes bounceScroller {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px); /* Moves up */
    }
    60% {
      transform: translateY(-10px); /* Moves up slightly */
    }
  }

  .score-pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1.5em;
    border-radius: 50px;
    background-color: #78BE20; /* green color for success */
    color: white;
    font-size: 1.2em;
    /* font-weight: bold; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
    
  }
  
  .score-text {
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);  */
  }
  

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .score2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #78BE20;
    color: white;
    font-weight: bold;
    font-size: 18px;
    animation: pulse 1.5s ease-in-out;
  }
  
  .score2-text {
    font-size: 0.9em;
  }
  

  .score-line {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }



.list-item.new-item .avatar-container {
    animation: pulse 1s ease-out 4;
}
