.ui-guide .nav-link.active {
  font-weight: 600;
}
.ui-guide .nav-link:hover {
  font-weight: 600;
}

.ui-guide > h1,
.ui-guide > h2,
.ui-guide > h3,
.ui-guide > h4,
.ui-guide > h5 {
  margin-bottom: 20px !important;
}

.nested-nav-link {
  padding-left: 20px; /* Indent nested list under UI Kit */
  margin: 0; /* Remove default margin */
}

.ui-guide .split-pane {
  display: flex;
  height: 100vh; 
  overflow: scroll; 
}

.ui-guide .left-pane {
  /* background-color: #eef2f5 !important; */
  padding: 12px;
  border-right: 1px solid #ccc; 
  transition: width 0.3s ease; 
  overflow: hidden; 
  position: sticky;
  top: 1px; /* needed for sticky positioning */
}

.ui-guide .left-pane.hidden {
  width: 0; 
  padding: 0; 
  opacity: .3;
  text-wrap: nowrap;
}

.ui-guide .left-pane.visible {
  width: auto; 
}

.ui-guide .right-pane {
  flex: 1; 
  padding: 12px;
  /* background-color: #ffffff;  */
}

.ui-guide .chevron-toggle {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: calc(250px - 20px); /* Adjust based on left pane width */
  cursor: pointer;
  background-color: #004b87;
  border-radius: 50%; /* Makes the div circular */
  padding: 8px; /* Adjust padding to control icon size */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: left 0.3s ease; /* Smooth transition when the pane hides */
  width: 35px; /* Width for the circular div */
  height: 35px; /* Height for the circular div */
}

.ui-guide .left-pane.hidden + .chevron-toggle {
  left: -5px; /* Adjust for hidden state */
}
