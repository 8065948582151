.animate-slide-up {
    animation: slideUpContent 0.15s ease-in-out forwards;
}

@keyframes slideUpContent {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-bounce {
    animation: bounce 1s ease-out 3;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
        background-color: #F5AA04;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        font-size: 24px;
    }

    60% {
        transform: translateY(-15px);
    }
}

.animate-wiggle {
    animation: wiggle 1s ease-out 5;
}

@keyframes wiggle {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(-15px);
    }

    70% {
        transform: translateX(15px);
    }
}

.animate-flip-vertical {
    animation: flipVertical 0.6s ease forwards;
}

@keyframes flipVertical {
    0% {
        transform: rotateX(-90deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg);
        opacity: 1;
    }
}