.breadcrumb {
  margin-top: 10px;
  margin-bottom: 10px !important;
}

/* custom divider for list items */
.breadcrumb-nav {
  --bs-breadcrumb-divider: '\203A';
}

.breadcrumb-item a:hover {
  /* padding: 4px 12px 4px 12px;
  background-color: #eef2f5 !important;
  margin-right: 10px;
  border-radius: 20px; */
  color: #000000;
  /* font-weight: 550; */
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: 600;
}

.breadcrumb-item a {
  text-decoration: none;
  font-weight: 500;
  padding: 4px 16px 4px 16px;
  color: #333f48 !important;
  text-decoration: none;
  /* background-color: #eef2f5 !important; */
  /* border-color: #eef2f5 !important; */
  border: solid 1px gray !important;
  font-size: 12px;
  border-radius: 5px;
}
.breadcrumb-item a:hover {
  color: #ffffff !important;
  background-color: #888B8B !important;
  border-color: #888B8B;
}

.breadcrumb-item .dropdown-toggle::after {
  display: none !important; 
}


/* ***************************************
Styles for the dropdown of hidden items
*************************************** */

/* The container <div> - needed to position the dropdown content */
.breadcrumb-item .dropdown {
  position: relative;
  display: inline-block;
}

/* Style The Dropdown Button */
.breadcrumb-item .dropdown-button {
  color: #333f48 !important;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-top: -15px;
}

/* Dropdown Content (Hidden by Default) */
.breadcrumb-item .dropdown-content {
  margin-left: -30px;
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 9999;
}

/* Links inside the dropdown */
.breadcrumb-item .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

