/* 

Styles for vertical and horizontal page tabs

*/


.vertical-tab-right {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  padding: 20px 10px 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 7px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  writing-mode: vertical-rl;
  /* text-orientation: upright; */
  z-index: 1000;
  /* Ensure it's on top */
  animation: expandTab 1s ease-in-out;
  /* Animation on initial render */
}

.vertical-tab-left {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  padding: 20px 10px 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 7px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  writing-mode: vertical-rl;
  /* text-orientation: upright; */
  z-index: 1000;
  /* Ensure it's on top */
  animation: expandTab 1s ease-in-out;
  /* Animation on initial render */
}



/* this widens the tab initially to catch the user's eye */
@keyframes expandTab {
  0% {
    width: 40px;
    /* Initial narrow width */
    padding: 20px 10px 20px;
  }

  50% {
    width: 100px;
    /* Expanded width */
    padding: 20px 30px 20px 30px;
  }

  100% {
    width: 40px;
    /* Return to original width */
    padding: 20px 10px 20px;
  }
}


.horizontal-tab-top {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 12px;
  letter-spacing: 2px;
  border-radius: 0 0 10px 10px;
  z-index: 1000;
  animation: expandTabHoriz 1s ease-in-out;
}

.horizontal-tab-bottom {
  position: fixed;
  bottom: 0; /* Stick to the bottom */
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 12px;
  letter-spacing: 2px;
  border-radius: 10px 10px 0 0; /* Round the top corners */
  z-index: 1000;
  animation: expandTabHoriz 1s ease-in-out;
}


@keyframes expandTabHoriz {
  0% {
    height: 40px;
    /* Initial narrow height */
    padding: 10px 20px;
    /* Adjust padding for horizontal layout */
  }

  50% {
    height: 70px;
    /* Expanded height */
    padding: 30px 20px;
    /* Adjust padding for expansion */
  }

  100% {
    height: 40px;
    /* Return to original height */
    padding: 10px 20px;
    /* Reset padding */
  }
}