
.custom-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.35em 0.75em;
    font-size: 1rem; /* Adjust size as needed */
    color: #fff;
    background-color: #67A617;
    border-radius: 1rem; /* Starts as a round shape */
    transition: width 0.2s ease, border-radius 0.2s ease;
    height: 2rem; /* Fixed height to maintain a round shape */
    margin-left: 10px;
  }
  
