/* hide scroll bar until the user hovers over it */
.table-container {
    overflow: hidden;
    /* prevents shift in content when scrollbar appears */
    scrollbar-gutter: stable;
}
.table-container.no-scroll {
    overflow: hidden;
    /* prevents shift in content when scrollbar appears */
    scrollbar-gutter: auto;
}

/* scrollbar overrides */
.table-container::-webkit-scrollbar {
    position: absolute;
}
.table-container::-webkit-scrollbar-track {
    background-color: #eef2f5 !important;
    border-radius: 20px;
}
.table-container::-webkit-scrollbar-thumb {
    background-color: #004b87 !important;
    border-radius: 20px;
    width: 12px;
}

/* on hover show scroll bars */
.table-container:hover {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
}
.table-container.no-scroll:hover {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.table-container.allow-scroll-x {
    overflow-x: scroll !important;
}

.table-container.allow-scroll-y {
    overflow-y: scroll !important;
}

thead {
    position: sticky;
    top: 0;
    background-color: white;
}

.not-sticky {
    position: inherit;
}

th {
    font-weight: 550;
    color: gray;
}

td {
    color: gray;
}

.table>:not(:first-child) {
    border-top: 1px solid silver !important;
}

/* custom hover color */
.table-hover tbody tr:hover td {
    --bs-table-accent-bg: #eef2f5 !important;
}


.table-widget th {
    font-weight: 550;
    font-size: 13px;
}

.table-widget td {
    color: gray;
    font-size: 12px;
}