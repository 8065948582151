.dashboard-wrapper .dashboard-header {
    border-bottom: 1px solid #dee2e6;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .dashboard-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  
  .dashboard-heading h4 {
    margin-top: 6px;
  }
  
  
  .dashboard-wrapper .image-placeholder {
    display: flex;
    justify-content: center;
  }
  
  .dashboard-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 4px 8px 0 10px !important;
    margin-bottom: 7px !important;
  }
  
  .dashboard-options .title-desc {
    display: flex;
    flex-direction: column;
  }
  .dashboard-options .title-desc p {
    margin-bottom: 0;
  }
  .dashboard-options .title {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  
  .dashboard-options .title h5 {
    margin-bottom: 2px !important;
  }
  .dashboard-options svg:hover {
    cursor: pointer;
  }
  .dashboard-options .icons {
    /* margin-top: -5px;
    display: flex;
    justify-content: space-around;
    align-items: space-between;
    align-content: space-around;
    gap: 5px;
    color: silver; */
  }
  
/* .dashboard-options .dropdown-toggle::after {
    display: none !important;
} */
  