.list-container {
  margin: 12px;
}

.list-container a {
  text-decoration: none;
}

.list-container .list-item {
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  animation: flipVertical .5s;
  text-decoration: none;
  color: #333f48;
  border-bottom: solid 2px #eef2f5;
}
.list-container .list-item.no-animation {
  animation: none !important; 
}

@keyframes slideIn {
  from {
    margin-left: 2%;
    width: 85%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes flipVertical {
  0% {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

/* last list item in the group */
/* .list-container .list-item:last-child {
    border-bottom: solid 2px white;
  } */

.list-container .list-item:hover {
  /* transform: scale(1.002);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
  background-color: #f8f8f8;
}

.list-container .list-item:hover img {
  transform: scale(1.1);
}

.list-item .item-container {
  display: flex;
  flex-direction: row;
}

.list-item .avatar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.list-item .count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;
}

.list-item .chevron {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;
}

.list-item .title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  margin-left: 10px;
}

.list-item .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1;
}

.list-item .sub-title {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 1;
}

.list-item .description {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 1;
}

/* when using a group container */
.list-container .group-title {
  font-size: 18px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 15px;
}

/* when using a group container, the last list item in the group */
.list-container .group-container .list-item:last-child {
  border: none !important;
  margin-bottom: 20px;
}

.list-empty {
  padding: 15px;
}