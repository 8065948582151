/*******************************************************************************/
/* search result items */

.menu .search-box {
    margin: auto;
    width: 200px;
}

.menu .search-item {
    /* border: solid 1px white; */
    border-radius: 12px !important;
    color: #333f48 !important;
    padding: 12px;
    background-color: white;
    text-align: left;
    /* margin: 1px; */
}

/* .menu .search-item:hover {
    z-index: 1002;
  } */
/* .menu .search-item:hover button {
    z-index: 1002;
  } */

.menu .search-item.selected {
    /* border: solid 1px lightgray; */
    /* background-color: #eef2f5 !important; */
}

.menu .star-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.35em 0.75em;
    font-size: .8rem; */
    /* Adjust size as needed */
    /* font-weight: bold;
    color: #fff; */
    /* background-color: #67A617; */
    /* border: solid 3px white;
    border-radius: 1rem; */
    /* Starts as a round shape */
    transition: width 0.2s ease, border-radius 0.2s ease;
    height: 1.8rem;
    /* Fixed height to maintain a round shape */
    width: 1.8rem;
    /* position: absolute;
    left: 25px;
    top: -10px; */
    /* this margin positioning was needed because the pill buttons dropdown to select widget subcategories would stack behind the icon */
    margin-left: -15px;
    margin-right: -10px;
    margin-top: -10px;
}

.menu .star-off {
    display: none;
    align-items: center;
    justify-content: center;
    /* padding: 0.35em 0.75em;
    font-size: .8rem; */
    /* Adjust size as needed */
    /* font-weight: bold;
    color: #fff; */
    /* background-color: #67A617; */
    /* border: solid 3px white;
    border-radius: 1rem; */
    /* Starts as a round shape */
    transition: width 0.2s ease, border-radius 0.2s ease;
    height: 1.8rem;
    /* Fixed height to maintain a round shape */
    width: 1.8rem;
    /* position: absolute;
    left: 25px;
    top: -10px; */
    /* this margin positioning was needed because the pill buttons dropdown to select widget subcategories would stack behind the icon */
    margin-left: -15px;
    margin-right: -10px;
    margin-top: -10px;
}

.menu .search-item:hover .star-off {
    display: flex
}

.menu .star-on {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.35em 0.75em;
    font-size: .8rem; */
    /* Adjust size as needed */
    /* font-weight: bold;
    color: #fff; */
    /* background-color: #67A617; */
    /* border: solid 3px white;
    border-radius: 1rem; */
    /* Starts as a round shape */
    transition: width 0.2s ease, border-radius 0.2s ease;
    height: 1.8rem;
    /* Fixed height to maintain a round shape */
    width: 1.8rem;
    /* position: absolute;
    left: 25px;
    top: -10px; */
    /* this margin positioning was needed because the pill buttons dropdown to select widget subcategories would stack behind the icon */
    margin-left: -15px;
    margin-right: -10px;
    margin-top: -10px;
}

.menu .search-item:hover {
    background-color: #eef2f5 !important;
    /* background-color: white !important; */
    border-radius: 12px !important;
    cursor: pointer;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
}

.menu .search-item button {
    /* z-index: 2999; */
}

.menu .search-item .upper-right-corner {
    opacity: 0;
}

/* when the parent container is hovered over, display the child image */
.menu .search-item:hover .upper-right-corner {
    opacity: 1;
}

.menu .search-item .upper-right-corner button:hover {
    transition: height 0.15s ease-in-out;
}


/*******************************************************************************/
/* pill button dropdown  */
.menu .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000 !important;
    border-radius: 5px !important;
}

/* Buttons inside the dropdown */
.menu .dropdown-content button {
    padding: 0px 0px 0px !important;
    display: block;
    margin-bottom: 10px !important;
    text-decoration: none !important;
}

.menu .dropdown-content button:hover {
    text-decoration: none !important;
}

.menu .dropdown-content .button-container {
    padding: 5px 5px 1px 10px;
}

.menu .dropdown-content .button-container:hover {
    background-color: #eef2f5 !important;
}

/* Show the dropdown content on hover */
.menu .item-pill:hover .dropdown-content {
    display: block;
}

/* pagination overrides */
.menu .page-link {
    display: inline !important;
    border: none !important;
}


/* favorite stars */
.menu .star-on {
    animation: expandStar 1s ease-in-out;
    transform-origin: center;
}

.menu .star-off {
    animation: expandStar 1s ease-in-out;
    transform-origin: center;
}

@keyframes expandStar {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}


.menu .no-caret.dropdown-toggle {
    padding: 0px !important;
}
.menu .no-caret.dropdown-toggle::after {
    display: none !important;
}

.menu > .no-caret.dropdown-toggle img, svg {

} 