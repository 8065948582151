.oc-filters .offcanvas-body {
    padding: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    color: #333f48 !important;
    /* background-color: #eef2f5 !important; */

}

/* ********************************************************************* */
/* accordion overrides */
/* ********************************************************************* */

.oc-filters .accordion {
    width: auto !important;
}

.oc-filters .accordion-header.white-background button {
    color: #333f48 !important;
    background-color: #ffffff !important;
    padding: 0px;
    border: none;
    /* font-weight: 600; */
    /* font-style: italic; */
}

.oc-filters .accordion-header.white-background button:after {
    margin-left: 10px;
}

.oc-filters .accordion-item {
    border: none !important;
}

.oc-filters .accordion-body {
    border-radius: 12px;
    margin-top: 10px;
    background-color: #eef2f5 !important;
    padding: 1rem 1.25rem 0.4rem;

}

.oc-filters .accordion-button:not(.collapsed) {
    box-shadow: none;
}

/* ********************************************************************* */

.oc-filters .saved-filter-buttons {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
}
