.maint .calendar-list-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 5px;
    border: solid .5px lightgray;
    background-color: white;
    margin-bottom: 5px;
}

.maint .loading-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 5px;
    border: solid .5px lightgray;
    background-color: white;
    animation: flipVertical 0.6s ease forwards;
    margin-bottom: 5px;
    padding: 12px;
}

.maint .calendar-list-item.active {
    border: solid 1px #E03C31;
}

.maint .calendar-list-item .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 80px;
    padding: 8px;
    flex-shrink: 0;
}

.maint .calendar-list-item .date .day-name {
    font-size: 16px;
    align-items: center;
}
.maint .calendar-list-item .date .day-name.small {
    font-size: 14px;
}

.maint .calendar-list-item .date .day-nbr {
    font-size: 32px;
    align-items: center;
    margin-top: -5px;
}
.maint .calendar-list-item .date .day-nbr.small {
    font-size: 14px;
}

.maint .calendar-list-item .vertical-line {
    width: 1px;
    height: 40px;
    background-color: #dad8d8;
}
