.copy-link .copy-link-container {
  display: flex;
  flex-direction: row;
  background-color: #eef2f5;
  margin-top: 12px;
  padding: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.copy-link-container .icon-container {
}
.copy-link-container .link-container {
  flex-grow: 2;
}
.copy-link-container .button-container {
}
