.dropdown {
  position: relative;
  color: gray;
  margin-bottom: 0px;
}
.dropdown .dropdown-list {
  padding: 12px;
  background: #fff;
  position: absolute;
  top: 40px;
  left: 2px;
  right: 2px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15);
  transform-origin: 50% 0;
  transform: scale(1, 0);
  transition: transform 0.15s ease-in-out 0.15s;
  max-height: 40vh;
  overflow-y: scroll;
  z-index: 999999;
}
.dropdown .dropdown-option {
  display: block;
  padding: 8px 12px;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.dropdown .dropdown-label {
  display: block;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 13px 12px 24px 12px;
  line-height: 1;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown.on .dropdown-list {
  transform: scale(1, 1);
  transition-delay: 0s;
}
.dropdown.on .dropdown-list .dropdown-option {
  opacity: 1;
  transition-delay: 0.2s;
}
.dropdown .dropdown-label:before {
  content: "▼";
  float: right;
}
.dropdown.on .dropdown-label:before {
  content: "▲";
}
.dropdown [type="checkbox"] {
  position: relative;
  float: right;
  top: -1px;
  margin-right: 4px;
}
.dropdown-option:hover {
  background-color: #f8f8f8;
}

/* This prevents the text of the list item from being selected */
.prevent-text-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
