/*********************************************************************************/
/* some big overrides to Bootstrap's style for buttons, tables, etc.
/*********************************************************************************/

/* Table overrides to Bootstrap */
.oc-report .oc-report-content-wrapper table th,
td {
  font-size: 10pt !important;
  padding: 6px !important;
}

/* For table cells that contain multiple elements */
.oc-report td .flex-elements {
  display: flex;
  flex-direction: column;
}

/*********************************************************************************/


/* .oc-report .offcanvas-end {
  top: 0;
  right: 0;
  width: 90vw !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
} */


.oc-report .report-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.oc-report .report-options .icons {
  margin-top: -5px;
  display: flex;
  justify-content: space-around;
  align-items: space-between;
  align-content: space-around;
  text-decoration: none;
  /* width: 180px; */
  gap: 10px;
}
.oc-report .report-options .icons .dropdown-toggle{
  padding: 0 0 0 0 !important;
}



.oc-report .form-check-input:checked {
  background-color: #67a617;
  border-color: #67a617;
}

.oc-report tr.hoverShade:hover {
  background-color: #eef2f5;
  /* cursor: pointer !important; */
}

.oc-report .filter-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.oc-report .filter-buttons button {
  margin-right: 10px;
}


/* Tabs overrides */
.oc-report .nav-link {
  color: #333f48 !important;
  font-size: 14px;
  /* padding: 0px; */
}

.oc-report .nav-link.active {
  font-weight: 600;
}

/* All button styles */

/* Trying to make a button look like a link */
.oc-report .btn.link-style {
  padding: 0px 0px 0px 0px !important;
  font-size: 10pt;
  margin-right: -10px;
  margin-top: -1px;
  text-decoration: underline;
  color: #333f48;
}

.oc-report .offcanvas-header .btn-close {
  color: white !important;
}

.oc-report .oc-report-content-wrapper .btn.btn-light {
  padding: 4px 16px 4px 16px;
  color: #333f48 !important;
  text-decoration: none;
  background-color: #eef2f5 !important;
  border-color: #eef2f5 !important;
  font-size: 12px;
}