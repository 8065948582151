/* offcanvas width */
.oc-shared.offcanvas-end {
    border-left: none !important;
    width: 90vw !important;
}

/* instead of the default 90vw, keep some offcanvas slideins at 50vw */
.oc-shared.width-50 {
    width: 50vw !important;
}

/* Offcanvas width for mobile devices (100% width) */
@media (max-width: 767px) {
    .oc-shared.offcanvas-end {
        border-left: none !important;
        width: 100vw !important;
    }
}

/* slidein animation speed */
.oc-shared.offcanvas {
    transition: transform .5s ease-in-out !important;
}

.oc-shared .offcanvas-header {
    background-color: #004b87;
    color: white;
    /* padding-bottom: 4px; */
}

.oc-shared .offcanvas-header .header-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.oc-shared .offcanvas-header .btn-close {
    filter: invert(100%) brightness(100%);
    opacity: 1; /* Ensure full visibility */
}

.oc-shared .offcanvas-body {
    padding: 0px;
    /* -ms-overflow-style: none; */
    /* for Internet Explorer, Edge */
    /* scrollbar-width: none; */
    /* for Firefox */
    /* overflow-y: scroll; */
    /* hide horizontal scrollbar */
    overflow-x: hidden;
}

.oc-shared .offcanvas-body::-webkit-scrollbar {
    /* display: none; */
    /* for Chrome, Safari, and Opera */
}

.oc-shared .subtitle-tall {
    min-height: 180px;
    background-color: #004b87;
}

.oc-shared .subtitle-short {
    background-color: #004b87;
    color: white;
    padding-top: 5px;
    padding-right: 15px;
    font-size: 14px;
}

.oc-shared .content-container {
    padding-left: 18px;
    padding-top: 12px;
    padding-right: 18px;
    padding-bottom: 18px;
}

.oc-shared .centered-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.oc-shared .btn-outline-secondary:hover {
    color: #fff;
    background-color: #888B8B !important;
    border-color: #888B8B;
}