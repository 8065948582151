.maintenance-banner {

    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color:#FFB81C;
    color: white;
    font-size: 15px;
    justify-content: center;
    border-radius: 5px;
    margin: 4px 4px 0 4px;
    cursor: pointer;
}