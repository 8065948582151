/*********************************************************************************/
/* these are classes that can be referenced by report developers
/*********************************************************************************/

/* React bootstrap injects its table class at the end of className */
.oc-report .oc-report-content-wrapper .table-width-100.table {
  width: 100% !important;
}

.oc-report .oc-report-content-wrapper .table-width-auto.table {
  width: auto !important;
}

.oc-report .oc-report-content-wrapper .align-right {
  text-align: right;
}

.oc-report .oc-report-content-wrapper .align-center {
  text-align: center;
}

.oc-report .oc-report-content-wrapper .font-size-sm {
  font-size: 11px;
}

.oc-report .oc-report-content-wrapper .font-weight-bold {
  font-weight: 600;
}

.oc-report .oc-report-content-wrapper .font-color-red {
  color: red;
}

/* subtotal-row and hover */
.oc-report .oc-report-content-wrapper .subtotal-row  {
  background-color: #eef2f5 !important;
}
.oc-report tr.subtotal-row:hover {
  background-color: #eef2f5 !important;
}

/* total row and hover */
.oc-report .oc-report-content-wrapper .total-row  {
  background-color: #E5E4E2 !important;
}
.oc-report tr.total-row:hover {
  background-color: #E5E4E2 !important;
}