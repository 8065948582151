.scroll-wrapper {
    position: relative;
    display: flex;
    /* this causes the items to spread out to fill the space */
    flex: 1;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    overflow-x: scroll;
    position: relative;
    /* Hide scrollbar for IE, Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
    flex-wrap: no-wrap;
    /* these margin overrides apologize for the internal padding */
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -12px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .scroll-container {
    padding: 5px;
    margin-left: 7px;
    gap: 7px;
    position: relative;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    overflow-x: scroll;
    position: relative;
    /* Hide scrollbar for IE, Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;
    flex-wrap: no-wrap;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .scroll-wrapper:hover {
    /* background-color: #e8e8e8; */
    /* box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.1); */
  }
  .scroll-wrapper .prev-button {
    visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 1000;
  }
  .scroll-wrapper .prev-button:hover {
    transform: scale(1.5);
    background-color: #ffb81c !important;
    color: white;
  }
  .scroll-wrapper .next-button {
    visibility: hidden;
    position: absolute;
    right: 0;
    z-index: 1000;
  }
  .scroll-wrapper .next-button:hover {
    transform: scale(1.5);
    background-color: #ffb81c !important;
    color: white;
  }
  .scroll-wrapper:hover.scroll-wrapper .prev-button {
    margin-left: -5px;
    height: 35px;
    width: 35px;
    background-color: silver;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  
    visibility: visible;
    cursor: pointer;
  }
  /* for mobile devices, just the user can just swipe left and right */
  /* this media query tries to catch all phones and most tablets */
  @media (max-width: 768px) {
    .scroll-wrapper:hover.scroll-wrapper .prev-button {
      visibility: hidden;
    }
  }
  
  .scroll-wrapper:hover.scroll-wrapper .next-button {
    margin-right: -5px;
    height: 35px;
    width: 35px;
    background-color: silver;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  
    visibility: visible;
    cursor: pointer;
  }
  /* for mobile devices, just the user can just swipe left and right */
  /* this media query tries to catch all phones and most tablets */
  @media (max-width: 768px) {
    .scroll-wrapper:hover.scroll-wrapper .next-button {
      visibility: hidden;
    }
  }
  
  .scroll-wrapper::-webkit-scrollbar:horizontal {
    display: none;
  }
  .scroll-wrapper::-webkit-scrollbar:vertical {
    display: none;
  }
  
  /* This prevents the text of the list item from being selected */
  .prevent-text-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  