.toaster-container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    align-content: space-between;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 9999;
    width: 325px;
    margin: 8px 8px 0 0;
    background-color: transparent;
    gap: 10px;
}

.toaster {

    display: flex;

    justify-content: space-between;
    align-items: flex-start;

    /* background-color: #bbdefb; */
    height: 100%;

    min-width: 270px;
    background-color: white;
    padding: 8px 12px 8px 12px;
    border-radius: 5px;
    padding: 8px;

    filter: drop-shadow(0px 1px 1px silver);

    width: 300px;
    animation: toastopen 1s;


}
@keyframes toastopen {
    from {
      opacity: .5;
    }
    to {
      opacity: 1;
    }
  }

  /***** Slide Left *****/

  @keyframes slideleft {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

.toaster>div {
    padding: 8px;
}

.toaster .icon {
    /* flex:0 1 auto; */
    align-self: auto;
}

.toaster .heading {
    font-weight: 600;
    flex: 1 1 auto;
    /* flex-grow:1; */
}

.toaster .heading .description {
    margin-top: 5px;
    font-weight: 500;
    font-size: 13px;
}

.toaster .close-icon {
    cursor: pointer;
    color: silver;
}