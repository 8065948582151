.dashboard-wrapper {
}

.dashboard-wrapper .dashboard-header {
  border-bottom: 1px solid #dee2e6;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dashboard-container {
  flex-grow: 1;
  padding: 0 7px;
  /* overflow-x: auto; */
}
.dashboard-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.dashboard-heading h4 {
  margin-top: 6px;
}


.dashboard-wrapper .image-placeholder {
  display: flex;
  justify-content: center;
}

.dashboard-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 4px 8px 0 10px !important;
  margin-bottom: 7px !important;
  height: 53px;
  border-radius: 5px;
}

.dashboard-options .title-desc {
  display: flex;
  flex-direction: column;
}

.dashboard-options .title-desc p {
  margin-bottom: 0;
}

.dashboard-options .title {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.dashboard-options .title h5 {
  margin-bottom: 2px !important;
}

.dashboard-options svg:hover {
  cursor: pointer;
}



/* side menu toggle */
#rotatable-image {
  transition: transform 0.5s ease;
}

#rotatable-image.rotated-left {
  transform: rotate(-180deg);
}

#rotatable-image.rotated-right {
  transform: rotate(0deg);
}

.scale-25 {
  transform: scale(1.25);
}