
.table-placeholder .table {
  width: 100% !important;
}

.table-placeholder .row {
  border-bottom: 1px gray;
}

.table-placeholder .col {
  margin: 8px;
  background-color: whitesmoke;
  padding: 5px;
  height: 35px;
  /* border-radius: 5px; */
  animation: changeColor 1s ease-in-out infinite;
}
@keyframes changeColor {
  from {
    background-color: whitesmoke;
  }

  to {
    background-color: #e0e0e0;
    opacity: 0.5;
  }
}

.table-placeholder .th-filler {
  height: 5px;
  min-width: 5px;
  padding: 8px;
  background-color: lightgray;
}

.table-placeholder .td-filler {
  height: 5px;
  min-width: 5px;
  padding: 8px;
  background-color: whitesmoke;
}

.table-placeholder tr {
  border-color: whitesmoke !important;
  border-style: solid;
  border-width: 0;
}

.table-placeholder .table> :not(caption)>*>* {
  padding: 1.5rem 1.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-color: whitesmoke !important;
}

.table-placeholder-td {
  padding: 12px !important;
  width: 5vw;
}
.table-placeholder-td:first-child {
  width: 15vw;
}