.loading.container-fluid {
  background-color: #eef2f5 !important;
}

@keyframes ph-gradiant {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.loading .ph-animated {
  position: relative;
  background: linear-gradient(to right, #e5e5e5, #d5d5d5 20%, #e5e5e5 70%);
  background-size: 200% 100%;
  animation: ph-gradiant 1.5s linear infinite;
}

.loading .ph-wrapper {
  width: 100vw;
}

.loading .ph-text-title,
.loading .ph-text,
.loading .ph-text-short {
  height: 0.7em;
  max-width: 100%;
  margin: 1em;
  border-radius: 0.25em;
}

.loading .ph-topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 60px;
  margin-bottom: 20px;
}

.loading .ph-topnav .left {

}
.loading .ph-topnav .right {
  display: flex;
  flex-direction: row;
  gap: 12px;
}


.loading .ph-logo {
  height: 40px;
  width: 150px;
  border-radius: 0px;
  background-color: #DBDBDB;
}

.loading .ph-link {
  height: 20px;
  width: 90px;
  border-radius: 20px;
  background-color: #DBDBDB;
}

.loading .ph-cta {
  display: flex;
  padding: 10px;
  height: 80px;
  width: 17%;
  margin-bottom: 20px;
  border-radius: 5px;
}
.loading .ph-cta-small {
  height: 30px;
  width: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.loading .ph-kpi-tall {
  height: 460px;
  margin-bottom: 20px;
  border-radius: 5px;
}


.loading .ph-icon {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: #DBDBDB;
}

.loading .ph-kpi {
  height: 20vh;
  margin-bottom: 20px;
  border-radius: 5px;
}

.loading .short {
  max-width: 60%;
}

.loading .shorter {
  flex-grow: 1;
  height: 20px;
  max-width: 60%;
  background-color: darkgray;
  border-radius: 20px;
}