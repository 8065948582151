.selected-count-badge {
  display: inline-flex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  /* background-color: white; */
  /* color: green; */
  transition: width 0.2s ease, border-radius 0.2s ease;
  margin-bottom: 10px;
  text-orientation: upright;
  min-height: 30px;
  min-width: 30px;
  border-radius: 30px;
}


.selected-count-badge.animate-bounce {
  animation: selectedCountBounce 1s ease-out 3 !important;
}

@keyframes selectedCountBounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
    background-color: white;
    color: #007bff;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    font-size: 2.5rem;
  }

  60% {
    transform: translateY(-15px);
  }
}