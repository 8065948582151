/* white background works better for this page */
.dt {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  
  .dt::-webkit-scrollbar {
    /* display: none; for Chrome, Safari, and Opera */
  }
  
.dt:hover {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
}

.dt section  {
    padding: 12px !important;
}

.dt .nav-item button {
    color: #333f48 !important;
    padding-left: 12px;
    font-size: 14px
}

.dt .nav-item .badge {
    margin-left: 10px;
    background-color: #E03C31 !important;

}


.dt .dt-left-panel {
    padding-top: 15px;
}

.dt .tab-content {
    padding: 12px;
}

.dt .chart-container {
    min-height: 300px;
    max-height: 400px !important;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.dt .table-container {
    background-color: #eef2f5;
    padding: 20px;
    border-radius: 2px;
    min-height: 50vh;
}

.dt .cta {
    margin-bottom: 30px;
}

.dt .cta button {
    width: 100%;
}

.dt h6 {
    margin-top: 15px;
    margin-bottom: 20px;
}

.dt .card {
    border: none !important;
    border-radius: 5px !important;
    background-color: white !important;
    margin-top: 12px;
}

.dt .card-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.dt .card-body .dates {
    text-align: right;
}


.dt .centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}