.layout-item {
    /* box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, .18); */
    border: solid 3px #eef2f5;
    border-radius: 5px;
    margin: 10px;
    min-height: 40px;
    width: 80px;
    background-color: #eef2f5;
}
.layout-item:hover {
    box-shadow: 4px 8px 10px 0 rgba(22, 22, 26, .18);
    border: solid 3px silver !important;  
    background-color: silver;   
    animation: expandSize .5s ease-in-out;
}
.layout-item.selected {
    box-shadow: 4px 8px 10px 0 rgba(22, 22, 26, .18);
    border: solid 3px silver !important;  
    background-color: silver;
}

.layout-item .layout-cell {
    background-color: white !important;
    margin: 2px;
    min-height: 20px;
    border-color: silver;
    border-radius: 2px;
}
.layout-item .layout-cell.selected {
    /* background-color: #c0c0c0; */
}

@keyframes expandSize {
    0% {
        transform: scale(1);
    }
  
    50% {
        transform: scale(1.2);
    }
  
    100% {
        transform: scale(1);
    }
  }