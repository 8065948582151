/* wrapper container for all colors */
.palette-list.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}
/* hover */
.palette-list.wrapper:hover {
  cursor: pointer;
}
/* on hover and active set the checkbox color green */
.palette-list.wrapper.active .checkbox {
  border: solid 4px #67A617;
}
.palette-list.wrapper.active svg {
  stroke: #67A617;
  stroke-width: 4px;
}
.palette-list.wrapper:hover .checkbox {
  border: solid 4px #67A617;
}
.palette-list.wrapper:hover svg {
  stroke: #67A617;
  stroke-width: 4px;
}
/* end wrapper */

.palette-list.color-name {
  font-size: 14px;
}

.palette-list.color {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  font-size: 9px;
  font-weight: 600;
}
/* change cursor if the container is active */
.palette-list.color:hover {
  cursor: move;
}
/* when dragging the item, change the opacity */
.palette-list.color.dragged {
  opacity: .4;
}

.palette-list .card {
  flex: 1 1 auto;
}
.palette-list .selected.card {
  border: solid #67A617 !important;
}
.palette-list .card:hover {
  top: -3px;
  box-shadow: 0px 10px 52px -13px rgba(0, 0, 0, 0.75);
}

.tipper {
  position: absolute;
  top: -55px;
  left: 0;
  background-color: #67A617;
  border-radius: 12px;
  border: solid 1px silver;
  font-size: 14px;
  z-index: 10;
  margin: 5px;
  color: white;
  box-shadow: 0px 10px 52px -13px rgba(0, 0, 0, 0.75);
}

.tipper::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Position at the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to perfectly center the triangle */
  border-left: 10px solid transparent; /* Transparent left side */
  border-right: 10px solid transparent; /* Transparent right side */
  border-top: 10px solid #67A617; /* Color of the triangle pointing upwards */
}

.tipper.fade-in-text {
  animation: tipperFadeIn .25s;
}
@keyframes tipperFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.palette-list .card-deck {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}