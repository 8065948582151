/*********************************************************************************/
/* some big overrides to Bootstrap's style for buttons, tables, etc.
/*********************************************************************************/

/* For table cells that contain multiple elements */
.oc-wizard td .flex-elements {
  display: flex;
  flex-direction: column;
}

/*********************************************************************************/


/* .oc-wizard .offcanvas-end {
  top: 0;
  right: 0;
  width: 90vw !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
} */
/* .oc-wizard.offcanvas.offcanvas-end {
  width: 90vw !important;
}
 */



/* Tabs overrides */
.oc-wizard .nav-link {
  color: #333f48 !important;
  font-size: 14px;
  /* padding: 0px; */
}

.oc-wizard .nav-link.active {
  font-weight: 600;
}

/* All button styles */

/* Trying to make a button look like a link */
.oc-wizard .btn.link-style {
  padding: 0px 0px 0px 0px !important;
  font-size: 10pt;
  margin-right: -10px;
  margin-top: -1px;
  text-decoration: underline;
  color: #333f48;
}

/* using the gray background in this modal */
.oc-wizard .offcanvas-body {
  padding: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  background-color: #eef2f5 !important;
}

.oc-wizard .offcanvas-header .btn-close {
  color: white !important;
}

.oc-wizard .oc-wizard-content-wrapper .btn.btn-light {
  padding: 4px 16px 4px 16px;
  color: #333f48 !important;
  text-decoration: none;
  background-color: #eef2f5 !important;
  border-color: #eef2f5 !important;
  font-size: 12px;
}


.oc-wizard .image-placeholder {
  display: flex;
  justify-content: center;
}