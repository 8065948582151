
/* DID NOT WORK */
/* Bootstrap Overrides */
/* :root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: red !important;
  --bs-secondary: red !important;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
} */

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&amp;display=swap'); */


html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
  color: #333f48 !important;
  background-color: #eef2f5 !important;

  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

/* Hides the scrollbar for Chrome, Safari, and Opera  */
body::-webkit-scrollbar {
  display: none;
}

/****************************************************************************************************/
/* This allow-vertical-scroll class can be used to provide a scrollbar, but is suppressed on mobile */
@media (min-width: 767px) {

  .allow-vertical-scroll {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    scrollbar-width: auto !important;
  }

  /* .allow-vertical-scroll:hover {
    overflow-y: scroll !important;
  } */

  /* Just using default styling for now */
  /*
  .allow-vertical-scroll::-webkit-scrollbar {
    display: block !important;
  }
  .allow-vertical-scroll::-webkit-scrollbar-track {
    background-color: #E0E0E0 !important;
    border-radius: 12px;
    width: 5px;
  }
  .allow-vertical-scroll::-webkit-scrollbar-thumb {
    background-color: #C8C8C8 !important;
    border-radius: 12px;
  }
  */
}
/****************************************************************************************************/



.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-primary {
  background-color: #004b87 !important;
}

.plus-button {
  position: absolute;
  float: right;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.truncate-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.no-select {
  user-select: none;
  /* Prevents text selection */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.nav-link {
  color: #333f48 !important;
}

.nav-link.active {
  color: #495057 !important;
  font-weight: bold;
}