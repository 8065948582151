/* Bootstrap Button overrides */

.btn-primary {
  background-color: #004B87 !important;
  border-color: #004B87 !important;
}

.btn-secondary {
  background-color: #888B8B !important;
  border-color: #888B8B !important;
}

.btn-success {
  background-color: #78BE20 !important;
  border-color: #78BE20 !important;
}

.btn-light {
  background-color: white !important;
  border-color: gray !important;
}

/* this is a custom class for <Button variant="link" className="btn-link-off"> */
.btn-link-off {
  color: #333f48 !important;
  padding: 0px !important;
  margin: 0px !important;
  text-decoration: underline;
  text-decoration-color: silver !important;
}
.btn-link-off:hover {
  color: #67A617 !important;
  text-decoration: underline !important;
}