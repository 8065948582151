@media (min-width: 576px) {
    /* .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    } */

    .custom-90w {
        width: 90vw !important; 
        max-width: 90vw !important;
        height: 95% !important; 
        max-height: 95vh !important;
    }
    .custom-90w .modal-content {
        height: 95% !important; 
        max-height: 95vh !important;
    }


}