.wizard .accordion-header button {
  color: #333f48 !important;
  background-color: #eef2f5 !important;
}

.wizard .accordion-header.white-background button {
  color: #333f48 !important;
  background-color: #ffffff !important;
}


.widget-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;
  background-color: #eef2f5 !important;
  overflow: scroll;
}

.widget-preview {

  display: inline-flex;
  align-items: center;
  justify-content: center;


  min-height: 200px;
  min-width: 250px;
  border: solid 1px silver;
  background-color: white;
  border-radius: 5px;
}

.expandable-container {
  width: 100%;
  /* Adjust width as needed */
  height: 48px;
  /* Initial height */
  background-color: #f0f0f0;
  /* Example background color */
  transition: height 0.15s ease-in-out;
  /* Animates the height change */
  overflow-y: hidden;
  /* Hide overflow initially */
  position: relative;
}

.expandable-container.expanded {
  height: 550px;
  overflow-y: scroll;
}

/* .header {
  background-color: #ddd;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
} */

.wizard .content {
  padding: 10px;
}


.wizard .accordion-body {
  min-height: 280px;
  max-height: 280px;
  overflow-y: scroll;
  /* background-color: #eef2f5 !important; */
}

/*******************************************************************************/
/* search result items */

.wizard .search-box {
  margin: auto;
  width: 200px;
}
.wizard .search-item {
  /* border: solid 1px white; */
  border-radius: 12px !important;
  color: #333f48 !important;
  padding: 12px;
  background-color: white;
  /* margin: 1px; */
}
/* .wizard .search-item:hover {
  z-index: 1002;
} */
/* .wizard .search-item:hover button {
  z-index: 1002;
} */

.wizard .search-item.selected {
  /* border: solid 1px lightgray; */
  /* background-color: #eef2f5 !important; */
}
.wizard .search-item.selected .selected-counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.35em 0.75em;
  font-size: .8rem; /* Adjust size as needed */
  font-weight: bold;
  color: #fff;
  background-color: #67A617;
  border: solid 3px white;
  border-radius: 1rem; /* Starts as a round shape */
  transition: width 0.2s ease, border-radius 0.2s ease;
  height: 1.8rem; /* Fixed height to maintain a round shape */
  width: 1.8rem;
  /* position: absolute;
  left: 25px;
  top: -10px; */
  /* this margin positioning was needed because the pill buttons dropdown to select widget subcategories would stack behind the icon */
  margin-left: -15px;
  margin-right: -10px;
  margin-top: -10px;
}

.wizard .search-item:hover {
  background-color: #eef2f5 !important;
  /* background-color: white !important; */
  border-radius: 12px !important;
  cursor: pointer;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
}

/* .wizard .search-item-button {
  text-decoration: none;
  text-align: left;
  color: #333f48 !important;
} */

.wizard .search-item .upper-right-corner {
  opacity: 0;
}
.wizard .search-item:hover .upper-right-corner .add-button img {
  animation: expandPlus 1s ease-in-out;
  transform-origin: center;
}
.wizard .search-item:hover .upper-right-corner .remove-button img {
  animation: expandPlus 1s ease-in-out;
  transform-origin: center;
}
/* when the parent container is hovered over, display the child image */
.wizard .search-item:hover .upper-right-corner {
  opacity: 1;
}
/* .wizard .search-item .upper-right-corner button:hover {
  transition: height 0.15s ease-in-out;
} */

.wizard .search-item .selected-counter {
  animation: expandCounter 1s ease-in-out;
  transform-origin: center;
}

@keyframes expandPlus {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.4);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes expandCounter {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.5);
  }

  100% {
      transform: scale(1);
  }
}

/*******************************************************************************/
/* pill button dropdown  */
.wizard .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 20px;
  z-index: 1000 !important;
  border-radius: 5px !important;
}

/* Buttons inside the dropdown */
.wizard .dropdown-content button {
  padding: 0px 0px 0px !important;
  display: block;
  margin-bottom: 10px !important;
  text-decoration: none !important;
}
.wizard .dropdown-content button:hover {
  text-decoration: none !important;
} 
.wizard .dropdown-content .button-container {
  padding: 5px 5px 1px 10px;
}
.wizard .dropdown-content .button-container:hover {
  background-color: #eef2f5 !important;
}

/* Show the dropdown content on hover */
.wizard .item-pill:hover .dropdown-content {
  display: block;
}

/* pagination overrides */
.wizard .page-link {
  display: inline !important;
  border: none !important;
}



