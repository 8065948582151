.oc-custom .sub-header-date {
    display: flex;
    align-items: flex-start;
    padding: 8px;
    margin-left: 10px;
  }
  
  .oc-custom .sub-header-date-nbr {
    font-size: 52px;
    color: white;
  }
  .oc-custom .sub-header-date-day {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color: white;
    line-height: 23px;
    padding-top: 15px;
    padding-left: 10px;
  }
  .oc-custom .sub-header-datelist-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
  }
  .oc-custom .sub-header-datelist-container .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: white;
  }
  .oc-custom .sub-header-datelist-container .date .day-name {
    font-size: 16px;
    text-transform: uppercase;
  }
  .oc-custom .sub-header-datelist-container .date .day-nbr {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .oc-custom .sub-header-datelist-container .date .day-nbr:hover {
    border-radius: 25px;
    height: 35px;
    width: 35px;
    background-color: white;
    color: #004b87;
    /* font-weight: 510; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
  }
  .oc-custom .sub-header-datelist-container .date .day-nbr.active {
    border-radius: 25px;
    height: 35px;
    width: 35px;
    background-color: white;
    color: #004b87;
    font-weight: 510;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  