.side-menu-wrapper {
  overflow: hidden;
  transition: min-width .5s ease;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
}
.sidemenu-container {
    position: relative;
    padding: 12px !important;
    background-color: white;
    border-radius: 5px;
    min-height: 80vh;
    border-radius: 5px !important;
  }
  
  /* **************************************************** */
  /* This is for the dark blue box at the top of the leftnav */
  /* **************************************************** */
  .sidemenu-hero {
    border-radius: 5px !important;
    padding: 12px !important;
    margin-bottom: 20px !important;
    background-color: #004b87 !important;
  }
  .sidemenu-hero h4 {
    font-size: 1.5rem;
    color: white;
  }
  .sidemenu-hero .search-icon {
    position: absolute !important;
    right: -5px !important;
    color: gray !important;
    margin-top: -2px !important;
    height: 24px !important;
  }
  .sidemenu-hero .btn-link {
    background-color: transparent !important;
  }
  
  /* **************************************************** */
  /* This is for category labels */
  /* **************************************************** */
  .sidemenu-cat-heading {
    border: none !important;
    border-left: 3px solid transparent !important;
    color: #eef2f5;
    font-size: 1rem;
    width: 100%;
  }
  .sidemenu-cat-heading:focus {
    background: white;
    border-left: 3px solid rgba(16, 75, 134, 0.53) !important;
  }
  .sidemenu-cat-name {
    font-size: 16px;
    color: gray;
  }
  
  /* **************************************************** */
  /* sub/child items */
  /* **************************************************** */
  .sidemenu-sub-item {
    margin-bottom: 1px;
    color: gray !important;
  }
  /* override the focus state for Bootstrap */
  .sidemenu-sub-item .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0 transparent !important;
  }
  .sidemenu-sub-item .btn {
    font-size: 16px;
    /* text-decoration: none; */
    display: flex !important;
    text-align: left !important;
    color: gray;
  }
  .sidemenu-sub-item .btn.active {
    font-weight: 500;
    font-size: 17px;
    color: gray;
    background-color: #f0f0f0;
    /* width: 100% !important; */
    /* display: flex; */
  }
  
  /* **************************************************** */
  /* overrides to boostrap's accordion */
  /* **************************************************** */
  .sidemenu .accordion-button:not(.collapsed) {
    color: gray;
    background-color: #fbfbfb !important;
    box-shadow: none !important;
    border-left: 4px solid rgba(16, 75, 134, 0.53) !important;
    font-weight: 900;
  }
  .sidemenu .accordion-flush .accordion-collapse {
    border-width: 0;
    background-color: #fbfbfb;
    border-left: 4px solid rgba(16, 75, 134, 0.53) !important;
  }
  .sidemenu .accordion-item {
    margin-left: -3px !important;
  }
  .sidemenu .accordion-body {
    padding: 0px !important;
  }
  .sidemenu .hide-accordion-toggle button:after {
    display: none !important;
  }
  .sidemenu .accordion-item .btn {
    border-radius: 0px !important;
    padding-left: 25px;
  }
  