.panel1.show,
.panel2.show {
  transition: all 0.2s ease-in-out;
}
.panel1.hide,
.panel2.hide {
  text-align: center;
  height: 0;
  overflow: hidden;
  display: none;
  transition: all 0.2s ease-in-out;
}

.tasklist .selected-list-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 12px;
}
.tasklist .selected-list-container .list-name {
  font-size: 16px;
  flex-grow: 2;
}
