

/* Feedback Form */
.selected-container {
  position: fixed;
  right: -375px;
  /* Hidden by default, width can be adjusted */
  top: 50%;
  transform: translateY(-50%);
  width: 375px;
  background-color: #fff;
  box-shadow: -2px 0 24px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  /* Slide animation */
  z-index: 999;
  /* Below the tab */
  padding-right: 50px;
  border-radius: 40px 0px 0px 40px;
  max-height: 80%;
  min-height: 80%;
  overflow-y: hidden;
}

.selected-container:hover {
  overflow-y: auto;
}

.selected-content {
  padding: 30px 0px 30px 30px;
}

.star-rating span {
  margin-top: 10px;
  font-size: 24px;
  color: #FFD700;
  /* Gold color for stars */
  cursor: pointer;
}

textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  /* Prevent resizing */
}

.badge-upper-left {
  text-orientation: upright;
}


.closer {
  position: absolute;
  top: 30px;
  right: 30px;
}