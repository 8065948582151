/* .assign-share .upper-right {
    margin-top: 12px;
}
@media screen and (min-width: 768px) {
    .assign-share .upper-right {
        margin-top: 0px !important;
        position: relative;
        float: right;
        top: -55px;
    }
}
.assign-share .no-caret.dropdown-toggle {
    padding: 0px !important;
    margin-top: -3px;
}
.assign-share .no-caret.dropdown-toggle::after {
    display: none !important;
}
.assign-share table th, td {
    font-size: 14px !important;
    padding: 6px !important;
    color: #333f48 !important;
}
.assign-share table th {
    background-color: #eef2f5 !important;
} 
.assign-share .allowed-group-name {
    text-wrap: nowrap;
}
*/

.hover-effect-with-pointer:hover {
  cursor: pointer;
  transform: scale(1.004);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

/* Use to help the user keep track of which group is being edited */
.assign-share section.selected {
    box-shadow: 0px 4px 10px #67A617;
}
.scroll-indicator {
    position: fixed;
    width: 80px;
    height: 80px;
    background-color: #004b87;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  /* Scroll Down Indicator in Bottom-Right */
  .scroll-down {
    bottom: 20px;
    right: 20px;
    /* animation: pulse 1.2s ease-in-out 1; */
    animation: bounce 1.2s ease-in-out 1; /* Bounce animation twice */
  }

  /* Scroll Up Indicator in Upper-Right */
  .scroll-up {
    top: 20px;
    right: 20px;
    animation: pulse 1.2s ease-in-out 1;
  }

  /* Show indicators */
  .visible {
    opacity: 1;
    visibility: visible;
  }

  /* Hover effect to draw attention */
  .scroll-indicator:hover {
    transform: scale(1.2);
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3); /* Grow */
    }
    100% {
      transform: scale(1); /* Shrink back */
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px); /* Moves up */
    }
    60% {
      transform: translateY(-10px); /* Moves up slightly */
    }
  }