.landingContainer {
  margin-left: -12px;
  margin-right: -12px;
}

.displayPeriodContainer {
  align-items: center;
  display: flex;
  margin: 12px;

  button {
    border-radius: 5px;
    height: 32px;
    padding: 0 12px;
  }
}

.ctaContainer {
  display: flex;
  flex-wrap: no-wrap;
  /* these margin overrides apologize for the internal padding */
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  overflow-x: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* &::-webkit-scrollbar {
      display: none;
    } */
}

.ctaEqualColumns>* {
  flex: 1;
}

.kpiContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* these margin overrides apologize for the internal padding */
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.card.layout-70 {
  width: 70px;
  margin-bottom: 15px;
}

.card {
  margin: 0 .5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, .18);
  border: none;
}



.card.widget {
  background-color: #ffffff;
  border: none !important;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 5px 5px 0px rgba(46,61,73,0.15);
}

.card-title {
  font-size: 1.1rem;
}

.card.cta {
  background: #2b3947;
  border-radius: 0.3rem;
  color: #e9ecef;
  margin: 1rem;
  padding: 1px
}

.card.layout {
  /* padding: 5px 15px 5px 15px; */
  margin: 10px;
  min-height: 40px;
  width: 100px;
}
.card.layout-70 {
  /* padding: 5px 15px 5px 15px; */
  /* margin: 10px; */
  /* min-height: 40px; */
  width: 70px;
}

.card-text {
  font-size: 12px;
}

.card .img-wrapper {
  max-width: 100%;
  height: 13em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card img {
  max-height: 100%;
}

@media (max-width: 767px) {
.card .img-wrapper {
  height: 17em;
}
}

.layout-cell {
  border: solid 1px #e0e0e0;
  background-color: #e0e0e0;
  margin: 2px;
  /* min-height: 40px; */
}
.layout-cell.selected {
  /* border: solid 1px gray; */
  background-color: #c0c0c0;
}