@keyframes placeholder-shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

/* generic block */
.placeholders .placeholder-block {
    width: 100%;
    height: 53px;
    background-color: #ffffff;
    background-image: linear-gradient(90deg, #ffffff 25%, #eef2f5 50%, #ffffff 75%);
    background-size: 200% 100%;
    animation: placeholder-shimmer 2.5s infinite;
    border-radius: 5px;
}

/**********************************************************/
/* kpis/widgets */
.placeholders .kpi-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.placeholders .kpi-placeholder {
    flex: 1 1 calc(50% - 20px);
    height: 325px;
    background-color: #ffffff;
    background-image: linear-gradient(90deg, #ffffff 25%, #eef2f5 50%, #ffffff 75%);
    background-size: 200% 100%;
    animation: placeholder-shimmer 2.5s infinite;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.placeholders .kpi-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.placeholders .title-placeholder {
    width: 40%;
    height: 16px;
    background-color: #eef2f5;
    border-radius: 4px;
    animation: placeholder-shimmer 2.5s infinite;
}

.placeholders .icon-placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35px;
    height: 10px;
}

.placeholders .icon-placeholder .dot {
    width: 10px;
    height: 10px;
    background-color: #eef2f5;
    border-radius: 50%;
    animation: placeholder-shimmer 2.5s infinite;
}

.placeholders .subcontent-placeholder {
    margin-top: 8px;
    flex-grow: 1;
    align-content: center;
}

.placeholders .sub-line {
    height: 30px;
    width: 80%;
    background-color: #eef2f5;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: placeholder-shimmer 2.5s infinite;
}

.placeholders .sub-line:nth-child(2) {
    width: 60%;
}

.placeholders .sub-line:nth-child(3) {
    width: 40%;
}

.placeholders .kpi-container .kpi-placeholder:nth-child(n+3) {
    flex: 1 1 calc(33.333% - 20px);
}

@media (max-width: 768px) {
    .placeholders .kpi-placeholder {
        flex: 1 1 100%;
    }
}


.placeholders .vertical-bar-chart-container {
    margin-top: 8px;
    flex-grow: 1;
    align-content: center;
}

.placeholders .vertical-bar-chart {
    width: 200px;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
}

.placeholders .vertical-bar-chart .bar {
    width: 18%;
    background-color: #eef2f5;
    border-radius: 3px;
}

/**********************************************************/
/* side menu */
.placeholders .side-menu-placeholder {
    width: 100%;
    background-color: white;
    padding: 16px;
    height: 100vh;
    overflow-y: auto;
    border-radius: 5px;
}

.placeholders .menu-item-placeholder {
    height: 20px;
    width: 100%;
    margin-bottom: 16px;
    background-image: linear-gradient(90deg, #ffffff 25%, #eef2f5 50%, #ffffff 75%);
    background-size: 200% 100%;
    animation: placeholder-shimmer 1.5s infinite;
    border-radius: 4px;
}

.placeholders .submenu-placeholder {
    margin-left: 20px;
    margin-top: 8px;
}

.placeholders .submenu-item-placeholder {
    height: 16px;
    width: 60%;
    margin-bottom: 12px;
    background-image: linear-gradient(90deg, #ffffff 25%, #eef2f5 50%, #ffffff 75%);
    background-size: 200% 100%;
    animation: placeholder-shimmer 1.5s infinite;
    border-radius: 4px;
}