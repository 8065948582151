/* wrapper container for all colors */
.palette.wrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-wrap: wrap;
}
/* hover */
.palette.wrapper:hover {
    cursor: pointer;
}
/* on hover and active set the checkbox color green */
.palette.wrapper.active .checkbox {
    border: solid 4px #67A617;
}
.palette.wrapper.active svg {
    stroke: #67A617;
    stroke-width: 4px;
}
.palette.wrapper:hover .checkbox {
    border: solid 4px #67A617;
}
.palette.wrapper:hover svg {
    stroke: #67A617;
    stroke-width: 4px;
}
/* end wrapper */


.palette.color-name {
    font-size: 14px;
}

.palette.color {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: 600;
}
/* change cursor if the container is active */
.palette.wrapper.active .palette.color:hover {
    cursor: move;
}
/* when dragging the item, change the opacity */
.palette.color.dragged {
    opacity: .4;
}

.palette.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 6px;
    border: solid 2px #D3D3D3;
}
